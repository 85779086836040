import breadcrumbComp from "../../common/breadcrumb-comp";
import commonService from "./common-api-service"
import EncryptUtility from "../../utility/encrypt-utility"
//import Utility from "../../../shared/utility.js";
import DisplayTextData from "../../common/display-text-data.vue";

export default {
  props: {
    showFeedbackForm: Boolean,
    pageName: String,
    url: String,
  },
  data() {
    return {
      issueId: "",
      backSlash: true,
      changeBugStatusDialog: false,
      showDeleteDialog: false,
      customPage: true,
      file: "",
      files: "",
      fileList: [],
      noFiles: true,
      refresh: true,
      dragging: false,
      images: [],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchBugs: "",
      showFeedbackForm: false,
      issueTitle: "",
      issueDescription: "",
      uploadFileName: "",
      url: "",
      isFormValid: false,
      bugData: [],
      filesTable: [],
      updatedHistoryTable: [],
      totalRecords: 0,
      max150Rule: [(v) => (v || "").length <= 150 || "Max Length of 150 character", (v) => !!v || "Field is required"],
      max1000Rule: [(v) => (v || "").length <= 1000 || "Max Length of 1000 character", (v) => !!v || "Field is required"],
      fileRules: [
        (files) => !files || !files.some((file) => file.size > 10e5) || "File size should be less than 10 MB!",
        (v) => !!v || "Field is required",
      ],

    };


  },
  async mounted() {
    this.issueTitle = this.pageName
  },

  methods: {
    // Fucntion to get Status Text

    //Reset Function
    resetFunction() {
      this.url = "",
        this.searchBugs = "";
      this.uploadFileName = "";
      this.issueTitle = "";
      this.issueDescription = "";
      this.totalRecords = 0;
      this.this.$refs.newIssueForm.resetValidation();
      this.showFeedbackForm = false;
      this.getUserIssueDetails();
    },

    //Close the popup
    //Upload Screenshots button click event
    uploadFiles() {
      if (this.uploadFileName.length > 3) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Only 3 files are allowed",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Change event of the upload
    onChange(e) {
      this.files = e.target.files || e.dataTransfer.files;
      if (!this.files.length) {
        this.dragging = false;

        return;
      } else {
        this.noFiles = false;
        if (this.files.length > 3) {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Max 3 files can be uploaded.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.noFiles = true;
          this.dragging = false;
          this.files = ""
        }
        for (let i = 0; i < this.files.length; i++) {
          this.createFile(this.files[i]);
        }
      }
    },
    //Checking file details
    createFile(file) {
      if (file.size > 15000000) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "File size is more than 15MB",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.noFiles = true;
        this.dragging = false;
        return;
      }
      this.file = file;
      this.dragging = false;
      this.fileList.push(file)
    },
    //Removing dragged file
    removeFile(name) {
      this.fileList = this.fileList.filter((x) => x.name !== name)
      if (!this.fileList.length > 0) {
        this.noFiles = true;
        this.file = ""
        this.dragging = false;
      }
    },
    //Submit Issue details to the API
    async submitIssue() {
      if (this.$refs.newIssueForm.validate()) {
        const formData = new FormData();
        for (var x = 0; x < this.fileList.length; x++)
          formData.append("files", this.fileList[x]);
        let pass = 2;
        let issueObj = {
          UserId: parseInt(this.userId),
          pass: parseInt(pass),
          title: this.issueTitle,
          description: this.issueDescription,
          url: this.url
        };
        let showMessage = true;
        let issueId = await commonService.postIssueTracker("post", issueObj, showMessage);
        if (issueId !== null && issueId !== undefined) {
          if (this.file !== "") {
            let showMessage = false;
            let uploadImage = await commonService.postIssueImages("post", issueId, formData, showMessage);
            if (uploadImage !== undefined) {
              this.file = "";
              this.noFiles = true;
            }
          }
          this.closeDialog();
        }
      }
    },

    //Close the popup
    closeDialog() {
      this.issueTitle = "";
      this.url = "",
        this.removeFile()
      this.issueDescription = "";
      this.uploadFileName = "";
      this.$refs.newIssueForm.resetValidation();
      this.showFeedbackForm = false;
      this.$emit("closeFeedback", this.showFeedbackForm)
    },

  },

  components: {
    breadcrumbComp,
    DisplayTextData
  },
};
